export default function (fileType: string) {
  switch (fileType) {
    case 'application/pdf':
      return '/images/file-icons/pdf.png';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return '/images/file-icons/doc.png';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return '/images/file-icons/xls.png';
    case 'text/csv':
      return '/images/file-icons/csv.png';
    default:
      return '/images/file-icons/documents.png';
  }
}
