<template>
  <div class="el-file-uploader" :class="{'--disabled': disabled !== false}">
    <v-dialog v-model="isOpen" content-class="el-file-uploader" width="auto" max-width="100%">
      <!-- File manager dialog trigger -->
      <template #activator="{ props }">
        <div class="el-file-uploader__slot-wrapper" v-bind="disabled === false ? props : null">
          <slot />
        </div>
      </template>

      <!-- File manager dialog card -->
      <v-card class="el-file-uploader__card" width="720" max-width="100%">
        <v-card-title>{{ title }}</v-card-title>
        <v-card-subtitle v-if="subTitle">
          {{ subTitle }}
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div class="el-file-uploader__file-drop-container" :class="{'--is-dragged-in': isFileDraggedIn}" @dragover.prevent="whenFileDraggedIn" @dragleave="whenFileDraggedOut" @drop.prevent="whenFileDropped" @click="triggerFileBrowsDialog">
                <div class="el-file-uploader__file-drop-intro-screen">
                  <div class="el-file-uploader__file-drop-container-icon">
                    <img :src="fileType === 'document' || fileType === 'csv' ? documents : images" alt="File uploader icon">
                  </div>
                  <div class="el-file-uploader__file-drop-container-title text-center">
                    {{ isFileDraggedIn ? 'Drop to upload' : 'Drag & Drop or Click to browse' }}
                  </div>
                  <div v-if="getSupportedFileTypesText()" class="file-drop-container-support-text">
                    <span v-if="fileType === 'csv'">Supports: csv</span>
                    <span v-else>Supports: {{ fileType === 'image' ? config.file.types.imageSupportedText : config.file.types.documentSupportedText }}</span>
                  </div>
                </div>

                <!-- Hidden file input element -->
                <v-file-input ref="fileInputRef" v-model="fileInput" :multiple="multiple !== false" class="el-file-uploader__file-drop-container-input-field" type="file" :accept="getInputFieldAcceptedFileTypes()" @change="prepareInputItemsToUpload" />
              </div>
            </v-col>
          </v-row>
          <div class="el-file-uploader__file-wrapper">
            <TransitionGroup name="list-pop">
              <div v-for="(displayFile, key) in displayFiles" :key="`display-file-${key}`">
                <ElementsFileUploaderDisplayFile v-model="selectedFiles" :display-file="displayFile" :maintain-aspect-ratio="maintainAspectRatio" :multiple="multiple !== false" :display-caption="displayCaption !== false" :width="width" :height="height" @remove-clicked="removeButtonClicked" @caption-updated="captionUpdated" />
              </div>
            </TransitionGroup>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click="cancelFileDialog">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn :disabled="selectedFiles.length < 1 || isFilesUploading || !hasChanged" color="primary" @click="triggerFileSelect">
            {{ actionButtonLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts" setup>
import {ComputedRef, PropType, Ref} from 'vue';
import images from '~/assets/images/icons/images.png';
import documents from '~/assets/images/icons/documents.png';
import {FileObject} from '~/@types/common';
import {alerts} from '~/composables/alerts';
import {useAppConfig} from '#app';

// EMIT Definitions
//----------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  (event: 'update:modelValue', payload: FileObject | FileObject[] | null): void;
  (event: 'fileUploaded', uploadedFile: FileObject | any): void;
  (event: 'removeClicked', removingFile: FileObject | any): void;
  (event: 'captionUpdated', updatedFile: FileObject | any): void;
}>();

// EXPOSE Definitions
//----------------------------------------------------------------------------------------------------------------------
defineExpose({
  resetFiles,
});

// PROPS Definitions
//----------------------------------------------------------------------------------------------------------------------
const props = defineProps({
  modelValue: {
    type: [Object, Array, null] as PropType<FileObject | FileObject[] | null>,
    default: null,
  },
  title: {
    type: String,
    default: 'Upload your file',
  },
  subTitle: {
    type: String,
    default: '',
  },
  variant: {
    type: String as PropType<'avatar' | 'image'>,
    default: 'image',
  },
  uploadPath: {
    type: String,
    required: true,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  fileType: {
    type: String as PropType<'image' | 'document' | 'csv' | 'any'>,
    default: 'image',
  },
  maxSizeInMb: {
    type: Number,
    default: 10,
  },
  preSetFiles: {
    type: Array as PropType<FileObject[]>,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  maintainAspectRatio: {
    type: Boolean,
    default: false,
  },
  width: {
    type: Number,
    default: 0,
  },
  height: {
    type: Number,
    default: 0,
  },
  autoSelectLastUploadedFile: {
    type: Boolean,
    default: true,
  },
  actionButtonLabel: {
    type: String,
    default: 'Update',
  },
  displayCaption: {
    type: Boolean,
    default: false,
  },
});

// DATA Definitions
//--------------------------------------------
const {$api} = useNuxtApp();
const isOpen: Ref<boolean> = ref(false);
const displayFiles: Ref<FileObject[]> = ref([]); // Responsible to display any images inside the display area
const selectedFiles: Ref<FileObject[]> = ref([]);
const isFileDraggedIn: Ref<boolean> = ref(false);
const fileInput: Ref<File[] | null> = ref(null);
const fileInputRef: Ref<HTMLElement | null> = ref(null);
const config = useAppConfig();

/**
 * @_MOUNTED
 */
onMounted(() => {
  setPresetFiles();
});

/**
 * @_WATCH preset file changes
 */
watch(() => props.preSetFiles, () => {
  setPresetFiles();
});

/**
 * @_WATCH model value changes
 */
watch(() => isOpen.value, () => {
  if (isOpen.value && props.modelValue) {
    if (Array.isArray(props.modelValue)) {
      selectedFiles.value = JSON.parse(JSON.stringify(props.modelValue));
    } else {
      selectedFiles.value = JSON.parse(JSON.stringify([props.modelValue]));
    }
  } else {
    selectedFiles.value = [];
  }
});

/**
 * Update model value
 */
function triggerFileSelect () {
  if (props.multiple === false) {
    if (selectedFiles.value.length > 0) {
      emits('update:modelValue', selectedFiles.value[0]);
    } else {
      emits('update:modelValue', null);
    }
  } else {
    emits('update:modelValue', selectedFiles.value);
  }
  isOpen.value = false;
}

/**
 * Set the give preset files
 */
function setPresetFiles () {
  if (Array.isArray(props.preSetFiles)) {
    displayFiles.value = props.preSetFiles;
  }
}

/**
 * When a file dragged in to the drag & drop area
 *
 * @param event
 */
function whenFileDraggedIn (event: DragEvent) {
  isFileDraggedIn.value = true;
}

/**
 * When a file dragged out from the drag & drop area
 */
function whenFileDraggedOut () {
  isFileDraggedIn.value = false;
}

/**
 * When a file dropped in to the drag & drop area
 *
 * @param event
 */
function whenFileDropped (event: DragEvent) {
  event.preventDefault();

  if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
    validateFileAndProceedToNext(event.dataTransfer.files);
  }

  isFileDraggedIn.value = false;
}

/**
 * Trigger file browse dialog
 */
function triggerFileBrowsDialog () {
  if (fileInputRef.value) {
    fileInputRef.value?.click();
  }
}

/**
 * If a file/files selected, send them to validate and upload
 */
function prepareInputItemsToUpload () {
  if (fileInput.value) {
    const files = Array.isArray(fileInput.value) ? fileInput.value : [fileInput.value];
    validateFileAndProceedToNext(files);
  }
}

/**
 * Validate the given input files and upload them
 *
 * @param files
 */
function validateFileAndProceedToNext (files: File[] | FileList) {
  for (const file of files) {
    // Validate file
    const fileSizeInMb = (file.size / (1000 * 1000));
    if ((props.fileType === 'any' || (config.file.types[props.fileType] && config.file.types[props.fileType].includes(file.type))) && fileSizeInMb < props.maxSizeInMb) {
      setFileDataUrls(file);
      if (props.multiple === false) {
        break;
      }
    } else {
      if (!(props.fileType === 'any' || (config.file.types[props.fileType] && config.file.types[props.fileType].includes(file.type)))) {
        setFileDataUrls(file, [`File type should be ${getSupportedFileTypesText()}!`]);
      } else if (fileSizeInMb > props.maxSizeInMb) {
        setFileDataUrls(file, [`File size should be less than ${props.maxSizeInMb}MB!`]);
      } else {
        setFileDataUrls(file, ['This file is not supported!']);
      }
    }
  }
}

/**
 * Set file data url and other meta data
 *
 * @param file
 */
function setFileDataUrls (file: File, errors: string[] = []) {
  const fileReader = new FileReader();
  fileReader.onload = () => {
    if (fileReader.result) {
      displayFiles.value.unshift({
        url: (fileReader.result as string),
        status: errors.length ? 'ERROR' : 'PREPARING',
        errors,
        fileObject: file,
        size: file.size,
        name: file.name,
        caption: '',
        type: file.type,
      });

      // Call the function to upload the file
      uploadFile();
    }
  };

  fileReader.readAsDataURL(file);
}

/**
 * Return true if any file is being uploaded or return false
 */
const isFilesUploading: ComputedRef<boolean> = computed(() => {
  return displayFiles.value.filter((file) => file.status && ['PREPARING', 'UPLOADING'].includes(file.status)).length > 0;
});

/**
 * Upload the file
 *
 */
async function uploadFile () {
  try {
    for (const file of displayFiles.value.filter((file) => file.status === 'PREPARING' && file.fileObject)) {
      file.status = 'UPLOADING';

      // Set the form data to upload
      var data = new FormData();
      data.append('file', (file.fileObject as Blob), file.name);
      data.append('path', props.uploadPath);

      // Upload the file
      const response = await $api.post('/common/file-manager/upload', data);

      if (response.code !== 200) {
        alerts().show('Sorry. Unable to upload. Please try again later', 'error');
        return;
      }

      // Update the file status
      file.status = 'SUCCESS';
      file.url = response.data;
      if (props.autoSelectLastUploadedFile) {
        if (typeof file.url === 'string') {
          setTimeout(() => {
            if (props.multiple === false) {
              selectedFiles.value = [{url: file.url, caption: file.caption, type: file.type}];
            } else {
              selectedFiles.value.push({url: file.url, caption: file.caption, type: file.type});
            }
          }, 100);
        }
      }
      emits('fileUploaded', {url: file.url, caption: file.caption, type: file.type});
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * File remove button clicked
 *
 * @param fileObject
 */
function captionUpdated (fileObject: FileObject) {
  emits('captionUpdated', fileObject);
}

/**
 * File remove button clicked
 *
 * @param fileObject
 */
function removeButtonClicked (fileObject: FileObject) {
  const removingFileIndex = displayFiles.value.findIndex((file: FileObject) => file.url === fileObject.url);
  if (removingFileIndex && displayFiles.value[removingFileIndex]) {
    displayFiles.value[removingFileIndex].status = 'DELETING';
  }

  // Unselect the deleted file if in the selected list
  const selectedFileIndex = selectedFiles.value.findIndex((selectedFile: FileObject) => selectedFile.url === fileObject.url);
  if (selectedFiles.value[selectedFileIndex]) {
    selectedFiles.value.splice(selectedFileIndex, 1);
  }
  emits('removeClicked', fileObject);
}

/**
 * Get the accepted file types for input field
 */
function getInputFieldAcceptedFileTypes () {
  switch (props.fileType) {
    case 'image':
      return config.file.types['image'].join(',');
    case 'document':
      return config.file.types['document'].join(',');
    case 'csv':
      return 'text/csv';
    default:
      return '*/*';
  }
}

/**
 * Get the accepted file types for input field
 */
function getSupportedFileTypesText () {
  let supportedTypesArray: string[] = [];
  switch (props.fileType) {
    case 'image':
      supportedTypesArray = config.file.types['image'];
      break;
    case 'document':
      supportedTypesArray = config.file.types['document'];
      break;
    case 'csv':
      supportedTypesArray = ['text/csv'];
      break;
  }

  let supportedTypeTextArray = [];
  for (const supportedType of supportedTypesArray) {
    supportedTypeTextArray.push(supportedType.split('/').pop());
  }

  return supportedTypeTextArray.join(', ').toUpperCase();
}

/**
 * Check has the selected files changed
 */
const hasChanged: ComputedRef<boolean> = computed(() => {
  const { modelValue } = props;
  const selectedUrls = selectedFiles.value.map(file => file.url);
  let modelValueData = null;
  if (Array.isArray(modelValue)) {
    modelValueData = modelValue.map(file => file.url);
  } else if (modelValue) {
    modelValueData = [modelValue.url];
  }

  return JSON.stringify(selectedUrls) !== JSON.stringify(modelValueData);
});

/**
 * Clear the selected files
 */
function resetFiles () {
  displayFiles.value = [];
  selectedFiles.value = [];
}

/**
 * Cancel and close the file dialog
 */
function cancelFileDialog () {
  resetFiles();
  isOpen.value = false;
}
</script>

<style lang="scss">
.el-file-uploader {
  position: relative;

  .el-file-uploader__slot-wrapper {
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    margin: auto;

    &:hover {
      &:first-child {
        scale: 1.02;
      }
    }

    transition: scale 0.2s ease-in-out;
  }

  &.--disabled {
    .el-file-uploader__slot-wrapper {
      cursor: default !important;
      scale: 1 !important;
    }
  }

  .el-file-uploader__file-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
  }

  .el-file-uploader__card {
    position: unset;
    min-height: 320px;
    padding-bottom: 30px;

    .v-card-actions {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      box-shadow: 0 0 15px -6px #00000040;
      background: #ffffff;
      z-index: 99;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }

  .el-file-uploader__file-drop-container {
    padding: 20px;
    height: 160px;
    background: #fafafa;
    border: 1px dashed #cccccc;
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 20px;

    &:hover {
      background: #f6f6f6;
    }

    &:active {
      background: #f1f1f1;
      border: 1px dashed $light-primary-light;
      -webkit-box-shadow: inset 1px 0 5px 2px rgba(0,0,0,0.1);
      box-shadow: inset 1px 0 5px 2px rgba(0,0,0,0.1);
    }

    &.--is-dragged-in {
      border: 2px solid $light-primary-light;
    }

    .el-file-uploader__file-drop-intro-screen {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      .el-file-uploader__file-drop-container-icon {
        img {
          width: 80px;
          height: auto;
        }
      }

      .el-file-uploader__file-drop-container-title {
        @include light-typography-h6;
        color: $light-text-primary;
        line-height: 24px;
      }

      .file-drop-container-support-text {
        @include light-typography-caption;
        color: $light-text-secondary;
        padding: 8px 0;
      }
    }

    .el-file-uploader__file-drop-container-input-field {
      display: none !important;
    }
  }

  .el-file-uploader__uploading-image-progress {
    display: flex;
    align-items: center;
    justify-content: stretch;
    border: 1px solid #f5f5f5;
    border-radius: 12px;
    height: 80px;
    position: relative;
    overflow: hidden;
    padding: 2px;

    &:before {
      content: '';
      position: absolute;
      width: 90%;
      height: 100%;
      background: rgb(120,126,255);
      background: linear-gradient(180deg, rgba(120, 126, 255, 0.05) 20%, rgba(117, 115, 178, 0.3) 100%);
      top: 0;
      left: 0;
    }

    .file-display-wrapper {
      width: 40%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      padding-left: 5px;

      img {
        max-width: 100%;
        max-height: 80px;
      }
    }

    .file-info-wrapper {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 12px 5px;

      .file-name {
        font-size: 14px;
        color: $light-text-primary;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 160px;
        height: 1.2em;
        white-space: nowrap;
      }

      .file-size {
        @include light-typography-caption;
        color: $light-text-secondary;
      }
    }
  }
}
</style>
