<template>
  <div v-if="displayFile && displayFile.url" class="el-file-uploader-display-file">
    <!-- Display files -->
    <div v-if="!['PREPARING', 'UPLOADING', 'ERROR'].includes((displayFile.status as string))" :class="{'--selected': isSelected, '--deleting': displayFile.status === 'DELETING'}" class="el-file-uploader-display-file__file-wrapper">
      <v-avatar v-if="maintainAspectRatio === false" class="ma-4" size="85" @click="toggleFileSelect">
        <v-img cover :src="getDisplayImageUrl(displayFile)" />
      </v-avatar>
      <v-img v-else class="el-file-uploader-display-file__custom-image" :lazy-src="getDisplayImageUrl(displayFile)" :src="getDisplayImageUrl(displayFile)" :style="customStyles" tabindex="-1" @click="toggleFileSelect" />
      <v-btn v-if="displayFile.removable !== false" class="display-file__remove-btn" color="error" icon="mdi-close" size="x-small" tabindex="-1" @click="emitRemoveClicked" />
      <v-text-field v-if="displayCaption !== false" v-model="caption" class="el-file-uploader-display-file__image-caption" placeholder="Image caption" variant="plain" @blur="captionUpdated" />
    </div>

    <!-- Error -->
    <div v-else-if="displayFile.status === 'ERROR'" class="el-file-uploader-display-file__file-wrapper --error">
      <v-avatar v-if="maintainAspectRatio === false" class="ma-4" size="85">
        <v-img cover :src="getDisplayImageUrl(displayFile)" />
      </v-avatar>
      <v-img v-else class="el-file-uploader-display-file__custom-image" :lazy-src="getDisplayImageUrl(displayFile)" :src="getDisplayImageUrl(displayFile)" :style="customStyles" tabindex="-1" />
      <div class="error-overlay">
        <v-icon color="error">
          mdi-alert-circle
        </v-icon>
        <span v-if="displayFile?.errors?.length" class="error-message">{{ displayFile?.errors.join(', ') }}</span>
        <span v-else class="error-message">Unsupported file!.</span>
      </div>
    </div>

    <!-- Preparing or uploading files -->
    <v-progress-circular v-else :indeterminate="['PREPARING', 'UPLOADING'].includes(displayFile.status as string)" color="primary" model-value="80" size="92" class="ma-4">
      <v-avatar size="85">
        <v-img cover :src="getDisplayImageUrl(displayFile)" />
      </v-avatar>
    </v-progress-circular>
  </div>
</template>

<script setup lang="ts">
import {ComputedRef, PropType, Ref} from 'vue';
import {FileObject} from '~/@types/common';
import fileIcon from '~/composables/fileIcon';
import {useAppConfig} from '#app';

// EMIT Definitions
//-----------------------------------------------
const emit = defineEmits<{
  (event: 'update:modelValue', payload: FileObject[] | null): void;
  (event: 'removeClicked', file: FileObject | any): void;
  (event: 'captionUpdated', file: FileObject | any): void;
}>();

// PROP Definitions
const props = defineProps({
  modelValue: {
    type: [Object, null] as PropType<FileObject | null>,
    default: null,
  },
  displayFile: {
    type: Object as PropType<FileObject>,
    required: true,
  },
  maintainAspectRatio: {
    type: Boolean,
    default: false,
  },
  width: {
    type: Number,
    default: 0,
  },
  height: {
    type: Number,
    default: 0,
  },
  displayCaption: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
});

// DATA Definitions
//-----------------------------------------------
const caption: Ref<string> = ref('');
const config = useAppConfig();

/**
 * @_MOUNTED
 */
onMounted(() => {
  setCaption();
});

/**
 * Set the caption
 */
function setCaption () {
  if (props.displayFile.caption) {
    caption.value = props.displayFile.caption;
  }
}

/**
 * Toggle the file selection
 */
function toggleFileSelect () {
  if (Array.isArray(props.modelValue) && props.multiple !== false) {
    if (props.modelValue.some((file) => file.url === props.displayFile.url)) {
      emit('update:modelValue', props.modelValue.filter((file) => file.url !== props.displayFile.url));
    } else {
      emit('update:modelValue', [...props.modelValue, props.displayFile]);
    }
  } else if (props.modelValue) {
    if (props.modelValue.url === props.displayFile.url) {
      emit('update:modelValue', []);
    } else {
      emit('update:modelValue', [props.displayFile]);
    }
  } else {
    emit('update:modelValue', [props.displayFile]);
  }
}

/**
 * Emit the remove button clicked event
 */
function emitRemoveClicked () {
  if (typeof props.displayFile.url === 'string') {
    emit('removeClicked', {url: props.displayFile.url, caption: props.displayFile.caption, type: props.displayFile.type});
  }
}

/**
 * Emit the caption updated event
 */
function captionUpdated () {
  if (caption.value) {
    emit('captionUpdated', {url: props.displayFile.url, caption: caption.value, type: props.displayFile.type});
  }
}

/**
 * Set is selected
 */
const isSelected: ComputedRef<boolean> = computed(() => {
  if (Array.isArray(props.modelValue) && props.modelValue.length > 0) {
    return props.modelValue.some((file) => file.url === props.displayFile.url);
  } else if (props.modelValue) {
    return props.modelValue.url === props.displayFile.url;
  }
  return false;
});

/**
 * Get custom styles for the image
 */
const customStyles: ComputedRef<string> = computed(() => {
  let returnStyleData = '';
  if (props.maintainAspectRatio !== false && (props.width || props.height)) {
    if (props.width && props.height) {
      returnStyleData += `width: ${props.width}px; height: ${props.height}px`;
    } else if (props.width) {
      returnStyleData += `width: ${props.width}px; height: auto`;
    } else {
      returnStyleData += `height: ${props.height}px; width: auto`;
    }
  }
  return returnStyleData;
});

/**
 * Get display image url
 * @param displayFile
 */
function getDisplayImageUrl (displayFile: FileObject) {
  if (!displayFile.type || config.file.types.image.includes(displayFile.type)) {
    return displayFile.url;
  } else if (config.file.types.document.includes(displayFile.type)) {
    return fileIcon(displayFile.type);
  } else {
    return '/images/file-icons/documents.png';
  }
}
</script>

<style lang="scss">
.el-file-uploader-display-file {
  width: fit-content;
  height: fit-content;
  position: relative;
  padding: 8px;

  .el-file-uploader-display-file__file-wrapper {
    .el-file-uploader-display-file__custom-image {
      transition: all 0.2s ease-in-out;
    }
    .v-avatar,
    .el-file-uploader-display-file__custom-image {
      cursor: pointer;

      transition: all 0.2s ease-in-out;
    }

    .display-file__remove-btn {
      position: absolute;
      right: 12px;
      top: 12px;
      opacity: 0;
      width: 20px;
      height: 20px;
      z-index: 2;
    }

    .el-file-uploader-display-file__image-caption {
      width: 100%;
      margin-top: -16px;

      input {
        text-align: center;
        color: $light-text-secondary;
        font-size: 12px;
        font-style: italic;
      }
    }

    &:hover {
      .v-avatar,
      .el-file-uploader-display-file__custom-image {
        scale: 1.05;
        border: 1px solid $light-primary-light;

        transition: all 0.2s ease-in-out;
      }

      .display-file__remove-btn {
        opacity: 1;
      }
    }

    &.--selected {
      .v-avatar,
      .el-file-uploader-display-file__custom-image {
        scale: 1.05;
        border: 6px solid $light-primary-light;

        transition: all 0.2s ease-in-out;
      }

      .el-file-uploader-display-file__custom-image {
        border-radius: 5px;
      }
    }

    &.--deleting {
      opacity: 0.5;
      transition: opacity 0.2s ease-in-out;
    }

    transition: all 0.2s ease-in-out;

    &.--error {
      border: 1px solid red;
      position: relative;
      overflow: hidden;

      .el-file-uploader-display-file__custom-image {
        border: unset !important;
      }

      .error-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(255,255,255,0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 5px;
        z-index: 1;

        .error-message {
          font-size: 11px;
          color: red;
          margin-top: 5px;
        }
      }
    }
  }
}
</style>
